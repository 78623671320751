export default {
  system: {
    zh: 'Chinese',
    en: 'English',
    text1: 'Share the course link: the link has been copied, feel free to share it with a friend~',
    text2: 'Submission successful',
    text11: 'Cancel',
    text12: 'Confirm',
    text21: 'Your browser does not support copying functionality',
    bottomTips: "Oops, you've hit the bottom!"
  },
  video: {
    welcome: {
      text1: "Welcome to",
      text2: "BrandPal's e-Learning Platform.",
      text3: "Let's start a new learning journey together!",
    },
    text2: "Check in now to unlock the course",
    text3: "We suggest the following learning approaches to help you gain better outcomes",
    text4: "Watch Videos",
    text5: "Further readings",
    text6: "Submit homework",
    text7: "Please enter your name",
    text8: "Check in",
    text9: "Learning Materials",
    text10: "Transcript",
    text11: "Homework",
    text12: "Popular questions",
    text13: "Please enter your answer",
    text14: "Submit",
    text15: "Feel free to ask any questions you may have here.",

    text16: "No reading materials available at the moment.",
    text23: "No homework at the moment",
    text24: "No transcript at the moment.",

    text25: "Please enter",
    text17: "Submit",
    text18: "Do it again",
    text19: "Download successful",
    text20: "Download failed",
    text21: "Submission successful",
    text22: "Submission failed",
    text26: "Confirm",

    text27: 'Notes',
    text28: 'Slides',
    text29: 'No notes at the moment.',
    text30: 'No slides at the moment.',
    text31: 'Delete',
    text32: 'Save',
    text33: 'Edit',
    text34: 'Add Note',

    text35: 'Please complete the quiz first before continuing',
    text36: 'Please save the note you are editing',

    text37: 'Confirm',
    text38: 'Cancel',
    text39: 'Are you sure you want to delete this note?',

    text40: 'Thank you for your feedback. Join our Discord community for personalized one-on-one consultation services. ',
    text41: 'Thank you for your Feedback, excited to progress together.',
    text42: 'AI Teaching Assistant',
    text43: 'Lecturer',

    text44: 'Please enter your answer.',

    text45: 'The BrandPal Academy is elevated by our proprietary AI model, LUMIA (Learning-Unified Multi-Intelligence Algorithm), an AI-powered Teaching Assistant designed to offer real-time voice responses to user queries, thereby enhancing the learning experience. While LUMIA is highly effective, we recommend verifying critical information as it can occasionally make errors.',

    text46: 'Disable Autoplay',
    text47: 'Congratulations! You have completed the final lesson of [{learningUnitName}].',
    text48: 'Replay',
    text49: 'Home',
    text50: 'You are currently at the first stage.',
    text51: 'You are currently at the final stage.',
    text52: 'You are currently at the first lesson.',
    text53: 'You are currently at the last lesson.',
    text54: 'Course Navigation',
    text55: 'Previous',
    text56: 'Next',

  },
  home: {
    text1: 'Home',
    text2: 'Ongoing',
    text3: 'Completed',
    text4: 'Validity',  // 'Validity period',
    text5: 'No ongoing courses at the moment.',
    text19: 'No completed courses at the moment.',
    text6: 'Current Progress',
    text7: 'Learning Progress',
    text8: 'Progress',
    text9: "You haven't started learning yet. <br> Start your learning journey now.",
    text10: "Study Again",
    text11: 'Free',
    text12: 'Continue',
    text13: 'Module',
    text13_2: 'Modules',
    text14: 'Course Content',
    text15: "This course unit haven't started yet.",
    text16: "This course unit has already ended.",
    text17: 'Show More',
    text18: 'Show Less',
    text20: 'Elevate TikTok influencer marketing with our AI-powered tool, streamlining search, outreach, and management.',
    text21: "Purchased Courses",
    text22: "No purchased courses",
    text23: "Courses you're interested in purchasing",
    text24: 'Cancel',
    text25: 'Continue payment',
    text26: 'Order cancelled successfully',
    text27: 'Purchased',
    text28: "You haven't purchased this course yet. Please purchase it before accessing the content.",
    text29: "No course units meet the criteria.",
  },
  course: {
    text1: 'Video Complete',
    text2: 'Homework Completed',
    text3: 'Video Incomplete',
    text4: 'Homework Incompleted',
    text5: 'Rewatch',
    text6: 'Start',
    text7: 'Continue',
    text8: 'Completed',
    text9: 'Ongoing',
    text10: 'Not Started',
  },
  header: {
    text1: 'Feedback',
    text2: 'Language',
    text3: 'Account',
    text4: 'Logout',
    text5: 'Feedback',
    text6: 'Feedback Type',
    text7: 'Detailed Description',
    text8: 'Share your insights and help us improve',
    text9: 'Please select feedback type',
    text10: 'Please enter detailed description',
    text11: 'Consultation',
    text12: "Interested in evaluating your LIVE Shopping events or learning more about BrandPal's comprehensive services? Schedule a personalized 1:1 consultation with a BrandPal specialist here: ",
    text13: 'Community',
    text14: "Become part of our Discord group to engage with fellow TikTok Sellers, stay informed about TikTok policies, and receive assistance from the BrandPal Teaching Assistant team. Join us here: ",
  },
  personal: {
    text1: 'Account Information',
    text2: 'Username',
    text3: 'Email',
    text4: 'Password',
    text5: 'Other',
    text6: 'Company',
    text7: 'Brand (If your brand and company names are the same, you do not need to respond.)',
    text8: 'TikTok Account',
    text9: 'Edit',

    text10: 'Edit Username',
    text13: 'Please enter the new user name',
    text14: 'Please enter a different username',
    text15: 'Edit Email Address',
    text16: 'Please enter the verification code',
    text17: 'Send',
    text18: 'Next',
    text19: 'Enter New Email address',
    text20: 'Please enter new email address',
    text21: 'Please enter a different email address',
    text22: 'Change Password',
    text23: 'Please enter new password',
    text24: 'Please re-enter new password',
    text25: 'Edit Company Name',
    text26: 'Please enter the Company name',
    text27: 'Please enter the Company name',
    text28: 'Please enter a different Ccompany name',
    text29: 'Edit Brand Name',
    text30: 'Please enter the Brand name',
    text31: 'Please enter the Brand name',
    text32: 'Please enter a different Brand name',
    text33: 'Edit TikTok Account',
    text34: 'Please enter your TikTok Account',
    text35: 'Please enter your TikTok Account',
    text36: 'Please enter a different TikTok Account',
    text37: 'Input cannot Exceed 100 Characters',
    text38: 'Input cannot Exceed 100 Characters',
    text39: 'Input cannot Exceed 100 Characters',
    text40: 'Input cannot Exceed 100 Characters',
    text41: 'Password must be 6-12 characters and include at least 2 of the following: numbers, letters, special characters',
    text42: 'Passwords do not match!',
    text43: 'Edit Successful',
    text44: 'The size of the uploaded image cannot exceed 10 MB.',
    text45: 'TikTok Shop Code',
    text46: 'Edit TikTok Shop Code',
    text47: 'Please enter your TikTok Shop Code',
    text48: 'Please enter your TikTok Shop Code',
    text49: 'Input cannot Exceed 100 Characters',
    text50: 'Please enter a different TikTok Shop Code',
    text51: 'Fill Out the Survey',
    text52: 'Note: <br/>The TikTok Shop Code is found on the<br/>TikTok Seller Center.Go to My Account, <br/>select Seller profile, and click Seller <br/>information.',

    text53: 'Referral Code',
    text54: 'Edit Referral Code',
    text55: 'Please enter your Referral Code',

    text56: 'My purchases',
    text57: 'View order detail',
  },
  purchase: {
    text1: 'All',
    text2: 'Pending Payment',
    text3: 'Successful Payment',
    text4: 'Unsuccessful Payment',
    text5: 'Refund in Progress',
    text6: 'Complete Refund',
    text7: 'Refund Error',
    text8: 'Order Cancelled',
    text9: 'My purchases',
    text10: 'If you have any questions or need a refund, click here for assistance.',
    text11: 'Order No',
    text12: 'Order creation time',
    text13: 'Payment time',
    text14: 'Refund time',
    text15: 'Order cancel time',
    text16: 'Email',
    text17: 'No order history',
  },
}