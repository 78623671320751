<template>
  <el-config-provider :locale="elLanguage">
    <div id="app" :style="{ 'font-family': currentFontFamily }">
      <router-view />
    </div>
  </el-config-provider>
</template>

<script>
import { useEventBus, useThrottleFn } from "@vueuse/core";
import { resize } from "./utils";
import AES from "@/utils/aes";
import { useSystemConfigStore } from "@/stores/systemConfig";
import { useUserInfoStore } from "./stores/userInfo";
import { getAllinfo } from "@/api/login";
import i18n from "@/lang";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import en from "element-plus/dist/locale/en.mjs";

let langageMap = new Map([
  ["zh_CN", "PingFang-fc"],
  ["en_US", "Poppins"],
]);

export default {
  name: "App",
  data() {
    return {
      systemStore: useSystemConfigStore(),
      userInfoStore: useUserInfoStore(),
      currentFontFamily: "",
      elLanguage: en,
    };
  },
  watch: {
    "systemStore.language"(val) {
      this.currentFontFamily = langageMap.get(val);
      this.elLanguage = this.systemStore.language == "zh-CN" ? zhCn : en;
    },
  },
  created() {

    this.currentFontFamily = langageMap.get(this.systemStore.language);
  },
  mounted() {
    if (
      this.systemStore.language == "en" ||
      this.systemStore.language == "zh"
    ) {
      this.systemStore.language = "en_US";
    }
    i18n.global.locale = this.systemStore.language;
    if (!this.userInfoStore.firstVisitTime) {
      this.userInfoStore.firstVisitTime = new Date().getTime();
    } else {
      if (
        new Date().getTime() - this.userInfoStore.firstVisitTime >
        1000 * 60 * 60 * 24
      ) {
        this.userInfoStore.isNewUser = false; // 不是新用户了，因为已经超过一天了
      }
    }
    if (!this.userInfoStore.visitorId) {
      this.userInfoStore.createVisitorId(); // 默认生成32位随机字符串作为访客ID
    }

    resize();
    const resizeBus = useEventBus("winResize");
    window.onresize = useThrottleFn(() => {
      resize();
      resizeBus.emit();
    }, 300);
  },
  methods: {},
};
</script>

<style lang="scss">
@import "./assets/fonts/font.css";

#app {
  font-family: PingFang-RE, PingFang-fc;
  // font-weight: 400;
  font-size: 14px;
}
</style>
