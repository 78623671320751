import axios from 'axios';
import AES from "@/utils/aes";
import { urlRegex, getInfoKeyValue } from '@/utils/index';
import { ElMessage } from 'element-plus';
import { useUserInfoStore } from '@/stores/userInfo';
import { useSystemConfigStore } from '../stores/systemConfig';
import mitt from "@/utils/mitt";


const md5 = require('js-md5');
const Base64 = require('js-base64').Base64;

// create an axios instance
let baseURL = import.meta.env.VITE_BASE_URL;
const service = axios.create({
  baseURL,
  timeout: 200000 // request timeout
});
// 特殊处理的url
let specialUrlSet = new Set(['/ext/aliyun/tts/text/to/audio/stream']);

let userInfoStore = null;
// request interceptor
service.interceptors.request.use(
  config => {
    const systemConfigStore = useSystemConfigStore();
    if (userInfoStore === null) {
      userInfoStore = useUserInfoStore();
    }
    // config.baseURL = baseURL;
    // do something before request is sent
    let token = userInfoStore.token;

    config.headers.country = systemConfigStore.currentCountryObj.countryNameAbbr;
    // 2024.4.15 格式改为en_US，写死为英文
    config.headers.language = systemConfigStore.language;

    if (token) {
      if (!config.url.startsWith('/')) {
        config.url = '/' + config.url;
      }
      if (config.baseURL.endsWith('/')) {
        config.baseURL = config.baseURL.slice(0, -1);
      }
      // console.log('config.baseURL', config.baseURL);
      let uri = urlRegex(config.baseURL) + config.url;
      let token_secret = AES.encrypt(token, getInfoKeyValue('token_secret_key'));
      let x_secret = AES.encrypt(new Date().getTime().toString(), getInfoKeyValue('x_secret_key'));
      let sign = Base64.encode(md5(uri + token + getInfoKeyValue('sign_secret_key')));
      // console.log('sign\n     ', sign, '\nuri     ', uri, '\ntoken  ', token, '\nsign_secret_key  ', getInfoKeyValue('sign_secret_key')[0].configValue, '\ndecode_sign   ', uri + token + getInfoKeyValue('sign_secret_key')[0].configValue);

      config.headers.x = x_secret;
      config.headers.uri = uri;
      config.headers.sign = sign;
      config.headers.token = token_secret;

      config.headers.tenantId = 1;
      config.headers.timezone = 'UTC-8';
      config.headers.userId = userInfoStore.userId;
    } else {
      config.headers = {
        x: '',
        uri: '',
        tenantId: 1,
        timezone: 'UTC-8',
        userId: 0
      };
    }
    // config.headers = {
    //     x: '',
    //     uri: '',
    //     tenantId: 1,
    //     timezone: 'UTC-8',
    //     userId: userInfoStore.userId,
    //     userNickname: encodeURIComponent(userInfoStore.userName),
    //     language: 'EN'
    // };

    return config;
  },
  error => {
    // do something with request error
    return Promise.reject(error);
  }
);
let logoutCount = 0;
// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;

    if (specialUrlSet.has(response.config.url)) {
      let customResponse = {
        success: true,
        resultMap: {
          data: res,
          ttsUrl: 'xx'
        }
      };
      return customResponse;
    }
    // return res;
    if (res.code !== 'C00001') {
      ElMessage({
        message: res.description || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
      if (res.code == 'C00995') {
        mitt.emit("logoutBus");
      }
      return res;
    } else {
      return res;
    }


  },
  error => {
    if (error?.response?.data?.code == 'C00995') {
      if (logoutCount == 0) {
        logoutCount++;
        ElMessage({
          message: error.response.data.description || '登录过期，请重新登陆！',
          type: 'error',
          onClose: () => {
            logoutCount--;
          }
        });
        mitt.emit("logoutBus");
      }
      return;
    } else {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(error);
    }
  }
);

export default service;