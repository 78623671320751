import CryptoJS from 'crypto-js';

export default {
    //随机生成指定数量的16进制key
    generatekey(num) {
        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);
        }
        return key;
    },

    //加密
    encrypt(value, key, iv = '0312032293271340') {
        value = value.toString(); // 数字加密会报错，转成字符串
        // value = CryptoJS.enc.Utf8.parse(value)
        key = CryptoJS.enc.Utf8.parse(key);
        iv = CryptoJS.enc.Utf8.parse(iv);
        // key = CryptoJS.enc.Latin1.parse(key)
        // iv = CryptoJS.enc.Latin1.parse(iv)
        const encrypted = CryptoJS.AES.encrypt(value, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return encrypted.toString();
    },
    //解密
    decrypt(value, key, iv = '0312032293271340') {
        // value = CryptoJS.enc.Utf8.parse(value)
        key = CryptoJS.enc.Utf8.parse(key);
        iv = CryptoJS.enc.Utf8.parse(iv);
        // key = CryptoJS.enc.Latin1.parse(key)
        // iv = CryptoJS.enc.Latin1.parse(iv)
        const decrypt = CryptoJS.AES.decrypt(value, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
        return decrypt.toString(CryptoJS.enc.Utf8);

    }

};
