import { defineStore } from "pinia";
import { isUndefined } from "lodash";
import { computed, ref } from "vue";

export const useUserInfoStore = defineStore(
  "userInfoConfig",
  () => {
    // 签到时输入的用户名
    const userName = ref("");

    // 随机生成id
    const userId: any = ref("");
    function createUserId() {
      let num: any = Math.random() * 10000;
      // userId.value = Number(Date.now() + `${parseInt(num)}`)
      userId.value = 1;
    }

    // 重置用户信息配置
    const $resetUserInfo = () => {
      userName.value = "";
      userId.value = "";
      userInfo.value = {};
      token.value = "";
      courseId.value = "";
      courseName.value = "";
      unitId.value = "";
      learnUnitCode.value = "";
      courseCode.value = "";
      promptMessage.value = "";
      lecturerId.value = "";
    };

    // 讲师ID、name 用于埋点
    const lecturerId = ref("");
    const lecturerName = ref("");
    // 课程讲师信息
    const lecturerObj = ref({
      name: "",
      id: -1,
      lecturerPhotoName: "",
    });
    // 单元ID、name
    const unitId = ref("");
    const unitName = ref("");
    // 单元code: 用于存分享链接带的单元code
    const learnUnitCode = ref("");
    // 课程名称
    const courseName = ref("");
    // 课程ID
    const courseId = ref("");
    // 课程code: 用于存分享链接带的课程code
    const courseCode = ref("");
    // 全部用户信息
    const userInfo = ref<any>({});
    const token = ref("");
    // 头像
    const avatarPath = computed(() => userInfo.value.headPortrait);
    // 设置用户信息配置
    function setData(params: {
      userInfo?: any;
      token?: string;
      userId?: Number;
    }) {
      userInfo.value = isUndefined(params.userInfo)
        ? userInfo.value
        : params.userInfo;
      token.value = isUndefined(params.token) ? token.value : params.token;
      userId.value = isUndefined(params.userId) ? userId.value : params.userId;
    }
    // 用户从还没学到的课程链接进入，后端返回应该学的课程时提示信息
    const promptMessage = ref("");

    // 随机访客ID 用于埋点
    const visitorId = ref("");
    function createVisitorId(length = 32) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      visitorId.value = result;
      return result;
    }
    // 新老用户判断逻辑 用于埋点： 首次访问的24小时内，属于新用户；其他为老用户
    const isNewUser = ref(true); // 默认是新用户，24小时后自动变老用户
    const firstVisitTime = ref(""); // 首次访问时间戳，用于判断新老用户
    return {
      userName,
      userId,
      createUserId,
      unitId,
      unitName,
      learnUnitCode,
      courseName,
      courseId,
      courseCode,
      userInfo,
      token,
      avatarPath,
      setData,
      $resetUserInfo,
      promptMessage,
      firstVisitTime,
      isNewUser,
      visitorId,
      createVisitorId,
      lecturerId,
      lecturerName,
      lecturerObj,
    };
  },
  {
    // 所有数据持久化
    // persist: true,
    // 持久化存储插件其他配置
    persist: {
      // 修改存储中使用的键名称，默认为当前 Store的 id
      key: "userInfoConfig",
      // 修改为 sessionStorage，默认为 localStorage
      storage: localStorage,
    },
  }
);
