import i18n from '@/lang/index';
/**
 * 设置i18n
 * @param {*} title i18nkey
 * @param {*} type 如果为path 则根据当前路由获取 对应的key 如果不为path 则获取对应key
 * @returns 
 */
export function showI18nText(title, type = 'path') {
    let pathMap = new Map([
        ['#/intelligentSquare', 'intelligentSquare'],
        ['#/intelligentManage', 'intelligentManage'],
        ['#/VideoSearch', 'VideoSearch'],
        ['#/ProductSearch', 'ProductSearch'],
        ['#/ProductDetail', 'ProductDetail'],
        ['#/StoreSearch', 'StoreSearch'],
    ]);

    if (type == 'path') {
        if (!pathMap.get(window.location.hash)) return '当前页面没有设置i18n';
    }

    let key = type == 'path' ? pathMap.get(window.location.hash) : type;
    const hasKey = i18n.global.te(`${key}.${title}`);

    if (hasKey) {
        return i18n.global.t(`${key}.${title}`);
    }
    return title;
}
