// import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
// import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';// element-ui lang
import enLocale from './en';
import zhLocale from './zh';

import { createI18n } from 'vue-i18n';

const messages = {
    en_US: {
        ...enLocale,
        // ...elementEnLocale
    },
    zh_CN: {
        ...zhLocale,
        // ...elementZhLocale
    },
};


const i18n = createI18n({
    // set locale
    // options: en | zh | es
    locale: 'en_US',
    // set locale messages
    globalInjection: true, // 可以在template模板中使用$t
    messages
});

export default i18n;
