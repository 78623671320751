import './style/base.scss';
// import './style/media.scss';

import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';

const app = createApp(App);

app.directive('focus', {
    mounted(el) {
        el.querySelector('input').focus();
    }
});

// 一个vue插件 快速实现文本的复制
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true; // add this line

// 全局组件
import noData from '@/components/noData/index.vue';
app.component('noData', noData);
import myButton from "@/components/MyButton/index.vue";
app.component('myButton', myButton);
import myConfirm from "@/components/MyConfirm/index.vue";
app.component('myConfirm', myConfirm);
import myDialog from "@/components/MyDialog/index.vue";
app.component('myDialog', myDialog);
import myCopy from "@/components/MyCopy/index.vue";
app.component('myCopy', myCopy);

// 数据持久化
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

//element
import ElementPlus from 'element-plus';
import './style/elementui.scss';

import i18n from "@/lang/index";

app.use(ElementPlus).use(pinia).use(router).use(i18n).mount('#app');
