import { defineStore } from 'pinia';
import { isUndefined } from 'lodash'
import AES from "@/utils/aes";

// 请求头
export const useSystemConfigStore = defineStore('systemConfig', {
    // 所有数据持久化
    // persist: true,
    // 持久化存储插件其他配置
    persist: {
        // 修改存储中使用的键名称，默认为当前 Store的 id
        key: 'systemConfig',
        // 修改为 sessionStorage，默认为 localStorage
        storage: window.localStorage,
    },
    state: () => {
        return {
            // 默认请求地址
            // baseUrl: [],
            baseUrl: '',
            _baseUrl: '',   // 缓存问题，过一个版本可以删除
            // 国家 语言
            language: 'en_US',  // 2024.4.15 格式改为en_US
            currentCountryObj: {
                "countryId": 0, //
                "countryNameAbbr": "ALL",
                "countryNameForShow": "All",
                "defaultSort": 0,
                "icon": ''
            },
        }
    },
    getters: {
        decodeBaseUrl: (state) => JSON.parse(AES.decrypt(state.baseUrl || state._baseUrl, "6plwu888B@M1x917"))
    },
    actions: {
        setData(params: {
            // baseUrl?: never[],
            baseUrl?: string,
            language?: string,
            currentCountryObj?: {
                "countryId": 0, //
                "countryNameAbbr": "ALL",
                "countryNameForShow": "All",
                "defaultSort": 0,
                "icon": ''
            }
        }) {
            const { baseUrl, language, currentCountryObj } = params
            this.baseUrl = isUndefined(baseUrl) ? this.baseUrl : baseUrl;
            this._baseUrl = isUndefined(baseUrl) ? this._baseUrl : baseUrl;
            this.language = isUndefined(language) ? this.language : language;
            this.currentCountryObj = isUndefined(currentCountryObj) ? this.currentCountryObj : currentCountryObj;
        },

        // 设置
    }
})
