export default {
  system: {
    zh: '中文',
    en: '英语',
    text1: '链接复制成功，快去分享给朋友吧~',
    text2: '提交成功',
    text11: '取消',
    text12: '确认',
    text21: '您的浏览器不支持复制功能',
    bottomTips: "哇，你翻到最后了！"
  },
  video: {
    welcome: {
      text1: "欢迎来到",
      text2: "BrandPal云课堂",
      text3: "我们将陪伴您开启一场新的旅程",
    },
    text2: "签到即刻为您解锁课程",
    text3: "我们建议尝试这样学习流程，可以帮助您获得更好的提升效果",
    text4: "观看视频",
    text5: "阅读材料",
    text6: "提交作业",
    text7: "请输入您的名称",
    text8: "签到",
    text9: "阅读资料",
    text10: "课程文稿",
    text11: "作业提交",
    text12: "猜您想问",
    text13: "请输入您想问的问题",
    text14: "发送",
    text15: "期待您在课堂中的精彩提问。",

    text16: "暂无阅读资料哦",
    text23: "暂无作业提交",
    text24: "暂无课程文档哦",

    text25: "请输入",
    text17: "提交",
    text18: "再做一次",
    text19: "下载成功",
    text20: "下载失败",
    text21: "提交成功",
    text22: "提交失败",
    text26: "确认",

    text27: '笔记',
    text28: '幻灯片',
    text29: '暂无笔记',
    text30: '暂无幻灯片',
    text31: '删除',
    text32: '保存',
    text33: '修改',
    text34: '新增笔记',

    text35: '请先回答问题',
    text36: '请先保存正在编辑中的笔记',

    text37: '确定',
    text38: '取消',
    text39: '您确定要删除当前笔记？',

    text40: '感谢您的反馈，加入Discord会给您提供一对一在线咨询服务',
    text41: '感谢您的支持，希望与您携手同行',
    text42: 'AI助教',
    text43: '讲师',

    text44: '请输入您的答案',

    text45: 'BrandPal Academy自研AI模型LUMIA（自进化多元智能算法）是一款由人工智能驱动的学习助理，能够对用户的提问进行实时语音回答，旨在增强用户的学习体验。尽管LUMIA功能强大，但仍难免出现错漏，我们建议您对关键信息进行核实。',

    text46: '停止连播',
    text47: '恭喜您！已看完【{learningUnitName}】的最后一节课程',
    text48: '重播',
    text49: '返回主页',
    text50: '当前已经是第一阶段',
    text51: '当前已经是最后一阶段',
    text52: '当前已经是第一节课',
    text53: '当前已经是最后一节课',
    text54: '课程列表',

    text55: '上一个',
    text56: '下一个',

  },
  home: {
    text1: '首页',
    text2: '进行中',
    text3: '已完成',
    text4: '有效期',
    text5: '暂无进行中的课程',
    text6: '当前学习进度',
    text7: '本单元总进度',
    text8: '进度',
    text9: `尚未开始学习课程，<br> 请开启您的学习旅程。`,
    text10: '再看一次',
    text11: '免费',
    text12: '继续',
    text13: '模块',
    text13_2: '模块',
    text14: '课程内容',
    text15: "此学习单元暂未开始",
    text16: "此学习单元已经逾期",
    text17: '展示更多',
    text18: '展示更少',
    text19: '暂未完成任何课程',
    text20: 'BrandPal达人营销AI工具，大幅提升TikTok商家筛选、触达、管理达人效率',
    text21: "已购课程",
    text22: "暂无已购课程",
    text23: '最近您想购买课程',
    text24: '取消',
    text25: '继续购买',
    text26: '取消订单成功',
    text27: '已购买',
    text28: '您暂未购买此课程，请先购买再观看学习',
    text29: "没有符合条件的课程单元",
  },
  course: {
    text1: '已看完课程视频',
    text2: '已完成课程作业',
    text3: '未看完课程视频',
    text4: '未完成课程作业',
    text5: '重新学习',
    text6: '开始学习',
    text7: '继续学习',
    text8: '已完成',
    text9: '进行中',
    text10: '未开始',
  },
  header: {
    text1: '反馈',
    text2: '语言',
    text3: '个人中心',
    text4: '退出登录',
    text5: '反馈',
    text6: '反馈类型',
    text7: '详细描述',
    text8: '分享您的见解以帮助我们改进',
    text9: '请选择反馈类型',
    text10: '请输入详细描述',
    text11: '咨询',
    text12: "想要评估您的TikTok直播带货表现？获得改进建议和提升？想要了解更多BrandPal的服务内容？即刻预约BrandPal团队TikTok专家的1v1咨询: ",
    text13: '社群',
    text14: "加入BrandPal专属TikTok商家社群，与头部TikTok商家互通有无、交换资源、交流经验，了解TikTok电商趋势和政策变动、并获得BrandPal团队的鼎力支持。即刻加入: ",
  },
  personal: {
    text1: '账号信息',
    text2: '用户名',
    text3: '邮箱',
    text4: '密码',
    text5: '其他信息',
    text6: '公司名称',
    text7: '品牌名称(与公司重名可以不填)',
    text8: 'TikTok账号',
    text9: '编辑',

    text10: '修改用户名',
    text13: '请输入用户名',
    text14: '请输入不同的用户名',
    text15: '修改邮箱',
    text16: '请输入验证码',
    text17: '发送验证码',
    text18: '下一步',
    text19: '填写新邮箱',
    text20: '请输入您的新邮箱',
    text21: '请输入您的新邮箱',
    text22: '修改密码',
    text23: '请输入您的密码',
    text24: '请再次输入您的密码',
    text25: '修改公司',
    text26: '请输入公司名称',
    text27: '请输入您的公司',
    text28: '请输入不同的公司',
    text29: '修改品牌名称',
    text30: '请输入品牌名称',
    text31: '请输入您的品牌名称',
    text32: '请输入不同的品牌名称',
    text33: '修改TikTok账号',
    text34: '请输入您的TikTok账号',
    text35: '请输入您的TikTok账号',
    text36: '请输入不同的TikTok账号',
    text37: '用户名不可超过100个字符',
    text38: '公司名称不可超过100个字符',
    text39: '品牌名称不可超过100个字符',
    text40: 'Tiktok账号不可超过100个字符',
    text41: '注册密码长度为6至12位，须包含数字、字母、符号至少2种及以上元素',
    text42: '密码不匹配',
    text43: '修改成功',
    text44: '上传图片大小不能超过10M',
    text45: 'TikTok商店码',
    text46: '修改TikTok商店码',
    text47: '请输入您的TikTok商店码',
    text48: '请输入您的TikTok商店码',
    text49: 'Tiktok商店码不可超过100个字符',
    text50: '请输入不同的TikTok商店码',
    text51: '请填写问卷',
    text52: '备注：<br>TikTok商店码可以在TikTok卖家中<br>心找到，进入“我的账户”，选择<br>“卖家资料”，然后点击“卖家信息”。',

    text53: '邀约码',
    text54: '修改邀约码',
    text55: '请输入你的邀约码',
    text56: '购买记录',
    text57: '查看订单详情',

  },
  purchase: {
    text1: '全部',
    text2: '待支付',
    text3: '支付成功',
    text4: '支付失败',
    text5: '退款中',
    text6: '退款完成',
    text7: '退款异常',
    text8: '订单取消',
    text9: '购买记录',
    text10: '如有疑问或退款需求，点此为您服务',
    text11: '订单号',
    text12: '订单创建时间',
    text13: '支付时间',
    text14: '退款时间',
    text15: '订单取消时间',
    text16: '邮箱',
    text17: '暂无订单记录',
  }
}; 