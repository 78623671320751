<template>
    <div v-if="show" class="my-confirm">
        <div class="my-confirm--box">

            <div class="title">
                <img src="@/assets/icon/icon11.png" alt="">
                <span>{{ _title }}</span>
            </div>
            <div class="content">{{ _content }}</div>
            <div class="footer-btn">
                <span @click="cancel">{{ $t("video.text38") }}</span>
                <span @click="save" class="save">{{ $t("video.text37") }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: false,
        type: Boolean
    },
    // title
    title: {
        type: String,
        default: '',
    },
    // 内容
    content: {
        type: String,
        default: '',
    },
});

const _title = computed(() => props.title);
const _content = computed(() => props.content);

const show = defineModel(
    () => props.modelValue,
    (value) => emit("update:modelValue", value)
);


const emits = defineEmits(['saveConfirm', 'cancelConfirm']);
function save() {
    emits('saveConfirm');
}
function cancel() {
    show.value = false;
    emits('cancelConfirm');
}




</script>

<style lang="scss" scoped>
.my-confirm {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba($color: #000000, $alpha: 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;

    .my-confirm--box {
        min-width: 340px;
        min-height: 188px;
        background: #FFFFFF;
        border-radius: 16px 16px 16px 16px;
        padding: 24px 32px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
            font-size: 18px;
            font-weight: 600;
            color: #191919;
            margin-bottom: 20px;
            text-align: center;
            margin-top: 20px;

            img {
                width: 48px;
                height: 48px;
            }
        }

        .content {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            margin-bottom: 24px;
            text-align: center;
            max-width: 400px;
            flex: 1;
        }

        .footer-btn {
            display: flex;
            justify-content: center;

            span {
                width: 132px;
                height: 38px;
                line-height: 38px;
                border-radius: 20px 20px 20px 20px;
                opacity: 1;
                border: 1px solid #191919;
                cursor: pointer;
                text-align: center;
            }

            .save {
                background: #191919;
                margin-left: 12px;
                color: #fff;
            }
        }

    }
}
</style>