
import { extractIdentifiers } from 'vue/compiler-sfc';
import request from './request';
import { getConfig } from '@/utils/index.js';

// 请求配置
export function getAllinfo() {
    return request({
        url: '/aic-sso/aic-sso-api/ext/app/https/appconfig/query?appId=1',
        method: 'get',
    });
}

// 请求ppt数据
export function getPPT(params) {
    return request({
        url: 'ext/course/ppt/query/course/ppt',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 请求playAuth
export function getPlayAuth(params) {
    return request({
        url: 'ext/course/by/courseid/vod/playauth/query',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 获取课程问答列表
export function getQuestion(params) {
    return request({
        url: 'ext/course/question/query/course/question',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 回到课程问答
export function postQuestion(data) {
    return request({
        url: 'ext/course/question/answer/question',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 获取阅读资料 课程文档 作业提交
export function getReadMaterial(params) {
    return request({
        url: 'ext/course/script/query/course/script',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 获取课程信息
export function getCourseInfo(params) {
    return request({
        url: 'ext/course/query/course/by/courseId',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 提交作业
export function postHomework(data) {
    return request({
        url: 'ext/course/homework/submit/homework',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 签到
export function postSignIn(params) {
    return request({
        url: 'ext/course/checkin/add/checkin/info',
        method: 'post',
        // params,
        data: params,
        baseURL: getConfig('mainbiz')
    });
}

// 查询笔记
export function getBookmark(params) {
    return request({
        url: 'ext/course/user/bookmark/query/user/bookmark/list',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 提交书签
export function postBookmark(data) {
    return request({
        url: 'ext/course/user/bookmark/add/user/bookmark',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}
// 编辑书签
export function putBookmark(data) {
    return request({
        url: 'ext/course/user/bookmark/update/user/bookmark',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}
// 删除书签
export function delBookmark(data) {
    return request({
        url: 'ext/course/user/bookmark/delete/user/bookmark',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 开始学习
export function postStartStudy(data) {
    return request({
        url: '/ext/user/learning/progress/start/course/learning',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 更新学习进度
export function putStudyProgress(data) {
    return request({
        url: '/ext/user/learning/progress/update/course/learning/progress',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 完成学习
export function postEndStudy(data) {
    return request({
        url: '/ext/user/learning/progress/complete/video/learning',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 完成学习
export function getTeacherInfo(params) {
    return request({
        url: '/ext/course/lecturer/by/courseid/query',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 记录用户ai反馈
export function postFeedback(data) {
    return request({
        url: '/ext/course/ai/reply/like',
        method: 'post',
        data,
        baseURL: getConfig('mainbiz')
    });
}

// 查询某个单元下课程列表（包含播放信息）
export function getLearningCourse(params) {
    return request({
        url: '/ext/course/learning/unit/list/course',
        method: 'get',
        params,
        baseURL: getConfig('mainbiz')
    });
}

// 查询课程视频的播放信息
export function getCourseVideos(params) {
    return request({
        url: '/ext/course/by/courseid/vod/playList/query',
        method: 'GET',
        params,
        baseURL: getConfig('mainbiz')
    });
}
