import { defineStore } from 'pinia';
import { ref } from 'vue'

export const useOrderStore = defineStore('OrderStore', () => {
  // 待支付订单
  const orderNo = ref('')
  // 待支付订单信息
  const orderInfo = ref({})
  // 由于未购买返回首页之前要去的路由
  const fromRouter = ref({})

  return {
    orderNo,
    orderInfo,
    fromRouter
  }
}, {
  // 所有数据持久化
  // persist: true,
  // 持久化存储插件其他配置
  persist: {
    // 修改存储中使用的键名称，默认为当前 Store的 id
    key: 'OrderStore',
    // 修改为 sessionStorage，默认为 localStorage
    storage: sessionStorage,
  },
})
