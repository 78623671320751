<template>
    <span class="copy-icon" @click.stop="copyText">
        <img v-if="_icon" :src="_icon" alt="" />
        <img v-else src="@/assets/icon/share.png" alt="" />
        <span v-if="_label">{{ _label }}</span>
    </span>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch, unref } from "vue";
import { useClipboard } from "@vueuse/core";
import { ElMessage } from "element-plus";
import { showI18nText } from "@/utils/i18n.js";

const props = defineProps({
    text: {
        default: "",
        type: String,
    },
    label: {
        default: "",
        type: String,
    },
    icon: {
        default: "",
        type: String,
    },
});
const _text = computed(() => props.text);
const _label = computed(() => props.label);
const _icon = computed(() => props.icon);

const { text, copy, copied, isSupported } = useClipboard({ copiedDuring: 200 });

watch(
    () => unref(copied),
    (val) => {
        if (!val) {
            ElMessage({
                type: "success",
                message: showI18nText("text1", "system"),
            });
        }
    }
);

const emits = defineEmits(["copied"]);

/******* copy start  *********/
function copyText() {
    if (!isSupported) {
        ElMessage({
            type: "warning",
            message: showI18nText("text21", "system"),
            showClose: true,
            duration: 1500,
        });
        return;
    }

    // 点击按钮实现复制功能

    if (navigator.clipboard) {
        copy(props.text);
    } else {
        const input = document.createElement("input");
        input.setAttribute("value", props.text);
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
        ElMessage({
            type: "success",
            message: showI18nText("text1", "system")
        });
    }
    emits("copied");
}

/******* copy end  *********/
defineExpose({ copyText });
</script>

<style lang="scss" scoped>
.copy-icon {
    opacity: 0.7;
    cursor: pointer;
    transition: all 0.3s;
    margin: 0 4px;
    display: flex;
    align-items: center;

    span {
        margin-left: 4px;
    }

    img {
        width: 20px;
        height: 20px;
    }

    &:active {
        opacity: 1;
    }
}
</style>
