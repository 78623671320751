import { ElMessage } from "element-plus";
import { useSystemConfigStore } from "../stores/systemConfig";
import { cloneDeep } from 'lodash';
import i18n from '@/lang/index';
import { showI18nText } from "@/utils/i18n.js";

// 1.获取html的元素
export function resize() {
    let dom = document;
    let fs = dom.body.clientWidth / (1920 / 14);
    // 👇注意这里不能直接document.body.style
    dom.body.parentNode && (dom.body.parentNode.style = "font-size: " + fs + "px;");
}


// 获取图片
export function getImage(path) {
    return new URL(path, import.meta.url).href;
}

// 不知道是啥
export function getConfig(md) {
    const systemStore = useSystemConfigStore();
    let configInfo = systemStore.decodeBaseUrl;
    let configInfoCase = configInfo.find(item => item.configKey == md) || { configValue: '' };
    return configInfoCase.configValue;
}

// 不知道是啥
export function getInfoKey(data) {
    const systemStore = useSystemConfigStore();
    let baseURL = systemStore.decodeBaseUrl;
    return baseURL.filter(item => item.configKey == data) || [];
}

// 不知道是啥
export function getInfoKeyValue(data) {
    const systemStore = useSystemConfigStore();
    let baseURL = systemStore.decodeBaseUrl;
    let configInfoCase = baseURL.find(item => item.configKey == data) || { configValue: '' };
    return configInfoCase.configValue;
}

// 不知道是啥
export function urlRegex(url) {
    const regex = /^https?:\/\/.*?\/(.*)$/;
    const match = url.match(regex);

    let thirdSegment = '';
    if (match) {
        return thirdSegment = '/' + match[1];
    } else {
        return '';
    }
}

/**
 * 计算设计稿的1px 在不同尺寸屏幕的px大小
 * @param {*} px 
 */
export function computedPx(px) {
    let rem = (px / 16).toFixed(4);
    let fs = document.body.parentElement.style.fontSize ? document.body.parentElement.style.fontSize : '16px';
    fs = Number(fs.replace('px', ''));
    return Number((rem * fs).toFixed(2));
}

// 复制文本
export async function copyText(str, msg) {
    let clipboard = navigator.clipboard || {
        writeText: (str) => {
            let copyInput = document.createElement('input');
            copyInput.value = str;
            document.body.appendChild(copyInput);
            copyInput.select();
            document.execCommand('copy');
            document.body.removeChild(copyInput);
        }
    };
    if (clipboard) {
        await clipboard.writeText(str);
        ElMessage({
            type: 'success',
            message: msg ? msg : i18n.global.t('system.text1')
        });
    }
}

/**
 * 跳转平台
 * @param {*} id 跳转id
 * @param {*} platform 平台
 */
export function gotoPlatform(id, platform = 'tiktok') {
    let platformMap = new Map([
        'tiktok', 'https://shop.tiktok.com/view/product/',
        'tiktokShop', 'https://shop.tiktok.com/view/product/',
    ]);
    window.open(platformMap.get(platform) + id, 'target');
}


/**
 * 过滤数值显示
 * @param {*} num 数值
 * @param {*} type 类型 num 为数字计量 date 为时间计量
 * @returns 
 */
export function filterNum(num, type = 'num') {

    let objFun = {
        // 数字转换
        num: () => {
            let t1 = '';
            let unit1 = '';
            if (num < 1000) {
                t1 = num.toString();
            } else if (num >= 1000 && num < 1000000) {
                t1 = (num / 1000).toFixed(1);
                unit1 = 'K';
            } else if (num >= 1000000 && num < 1000000000) {
                t1 = (num / 1000000).toFixed(1);
                unit1 = 'M';
            } else if (num >= 1000000000 && num < 1000000000000) {
                t1 = (num / 1000000000).toFixed(1);
                unit1 = 'B';
            }

            // 过滤.0的情况
            if (t1.endsWith('.0')) {
                t1 = t1.replace('.0', '');
            }
            return t1 + unit1;
        },
        date: () => {
            let t2 = '';
            let unit2 = '';
            let num2 = parseInt(Number(num) / 1000);
            if (num2 < 60) {
                t2 = num2;
                unit2 = 'S';
            } else if (num2 < (60 * 60)) {
                t2 = parseInt(num2 / 60);
                unit2 = 'Mins';
            }

            return t2 + unit2;
        }
    };


    return objFun[type]();
}

/**
 * 合并两个数组并使用 propertyName 属性去重 事件复杂度O(n)
 * @param {Array} arr1 数组1
 * @param {Array} arr2 数组2
 * @param {string} propertyName 指定去重字段
 * @returns 合并&去重后的数组
 */
export function mergeAndRemoveDuplicates(arr1, arr2, propertyName) {
    console.log('合并去重', arr1, arr2, propertyName);
    let map = new Map();

    for (let i = 0; i < arr1.length; i++) {
        map.set(arr1[i][propertyName], arr1[i]);
    }

    for (let i = 0; i < arr2.length; i++) {
        if (!map.has(arr2[i][propertyName])) {
            map.set(arr2[i][propertyName], arr2[i]);
        }
    }

    return Array.from(map.values());
}

/**
 * 模糊搜索高亮字符串
 * @param {*} text 
 * @param {*} keyword 
 * @returns 
 */
export function higtSearchKeyword(text, keyword) {
    return text.replace(keyword, `<span class='search-keyword'>${keyword}</span>`);
}

/**
 * 显示默认的图片显示
 * @param {*} imgList 显示的图片集合
 * @param {*} param num 为替换几张图片 key 为需要替换的字段 如果填则替换整个index img 为默认显示的图片
 */
export function filterDefaultImage(imgList, param = {
    num: 3,
    key: '',
    img: '',
    item: {}
}) {
    let _imgList = cloneDeep(imgList);
    if (_imgList.length) return _imgList;

    // let defaultImg = require('@/assets/icon/defaultImage.png');
    let _params = {
        num: 3,
        key: '',
        img: '',
        item: {},
        ...param
    };
    for (let i = 0; i < _params.num; i++) {
        _imgList[i] = _params.item;
        let key = _params.key || i;
        _imgList[i][key] = _params.img || defaultImg;
    }

    return _imgList;
}


/**
 * 防抖函数
 */
export function debounce(func, wait) {
    let timeout;
    function debouncedFunc() {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            func.apply(context, args);
        }, wait);
    }
    debouncedFunc.cancel = function () {
        clearTimeout(timeout);
    };
    return debouncedFunc;
}

/**
 * 上传文件
 * @param {*} file 上传的文件
 * @param {*} module 模块编号
 * @returns 
 */
export function OSSFileUpload(file, module) {
    return new Promise(async (resolve, reject) => {
        const accessKeyId = getConfig('oss_access_key');
        const accessKeySecret = getConfig('oss_access_key_secret');
        const endPoint = getConfig('end_point');
        const bucketName = getConfig('bucket_name');

        console.log(accessKeyId, accessKeySecret, endPoint, bucketName);
        const client = new window.OSS({
            region: 'oss-us-west-1',
            accessKeyId: accessKeyId,
            accessKeySecret: accessKeySecret,
            endpoint: endPoint,
            bucket: bucketName,
            // secure: false,
            timeout: 180000,
        });

        try {
            let fileName = createUploadFileName({ name: file.name, module });
            const result = await client.put(fileName, file);
            resolve(result);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
}

/**
 * 文件上传
 * @param {*} params name 需要上传的文件名称 module 模块编号
 * 当前新增的模块编号 001
 * @returns 
 */
export function createUploadFileName(params = {
    name,
    module: '001'
}) {

    let _name = params.name || '';
    let _module = params.module || '001';
    if (!_name) return console.error('请传入文件名称');

    // 平台
    let platform = new Map([
        ['bpSaas', 1], // BPSAAS系统
        ['aic', 2], // AI云课堂C端
    ]);

    // fileType
    let fileTypeMap = new Map([
        ['other', 0], // 不确定文件类型
        ['mp4', 1], // 视频类型
        ['mp3', 2], // 音频类型
        ['png', 3], // 音频类型
        ['jpeg', 3], // 音频类型
        ['jpg', 4], // 音频类型
    ]);

    // 文件类型
    let fileType = _name.split('.').pop();
    let fileNum = fileTypeMap.get(fileType) || 0;

    let timeNum = Date.now();

    let randomStr = Math.random().toString(36).slice(2, 4);

    return `${platform.get('aic')}${_module}${fileNum}${timeNum}${randomStr}.${fileType}`;

}


// 下载文件
export function downloadFile(fileUrl, fileName) {
    if (!fileName) return ElMessage.error(showI18nText("text20", "video"));
    let downloadUrl = getConfig('bucket_url');
    if (!downloadUrl) return ElMessage.error(showI18nText("text20", "video"));
    const a = document.createElement('a');
    // a.target = '_blank';
    a.download = fileName;
    fileUrl = fileUrl.startsWith('/') ? + fileUrl : '/' + fileUrl;
    a.href = downloadUrl + fileUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
    ElMessage.success(showI18nText("text19", "video"));
}

// 秒数字 转 时分秒
export function formatSeconds(value) {
    let theTime = parseInt(value);// 秒
    if (isNaN(theTime)) return console.error('数字解析报错');

    let sec = 60; // 分
    let min = sec * 60; // 时
    let houer = min * 24; // 天

    if (theTime < sec) {
        return `00:00:${formatStr(theTime)}`;
    } else if (theTime < min) {
        let time1 = Math.floor(theTime / sec);
        let time2 = theTime - (sec * time1);
        return `00:${formatStr(time1)}:${formatStr(time2)}`;
    } else if (theTime < houer) {
        let time3 = Math.floor(theTime / min);
        let time4 = Math.floor((theTime - (min * time3)) / sec);
        let time5 = theTime - (min * time3) - (time4 * sec);
        return `${formatStr(time3)}:${formatStr(time4)}:${formatStr(time5)}`;
    }
}

function formatStr(num) {
    return num < 10 ? '0' + num : num;
}

// 生成一个fileName 
/**
 * 生成一个随机字符的文件名称
 * @param {*} type 文件后缀
 * @returns 
 */
export function createFileName(type = 'jpg') {
    return createRandom() + '.' + type;
}

/**
 * 生成随机字符串
 * @returns 
 */
export function createRandom() {
    return Math.random().toString(32).slice(2, 30);
}

// 视频截图
export function screenShot(videoDom) {
    var player = videoDom;   //获取video的Dom节点
    player.setAttribute("crossOrigin", "anonymous");  //添加srossOrigin属性，解决跨域问题
    var canvas = document.createElement("canvas");
    canvas.width = player.clientWidth;
    canvas.height = player.clientHeight;
    canvas.getContext("2d").drawImage(player, 0, 0, canvas.width, canvas.height);//截
    return canvas.toDataURL("image/png");  //将图片转成base64格式
}

// file 转blob
export function dataURItoBlob(base64Data) {
    var byteString;
    if (base64Data.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(base64Data.split(',')[1]);
    else
        byteString = unescape(base64Data.split(',')[1]);
    var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
};

// base64 转 file
export function videoShotBase64ToFile(base64) {
    let arr = base64.split(",");
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    let fileName = Math.random().toString(32).slice(2, 32) + '.jpeg';
    return new File([u8arr], fileName, { type: 'image/jpeg' });
}

// 通过链接打开新的地址
export function openUrl(url) {
    window.open(url, '_blank');
}

