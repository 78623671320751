import { ElMessageBox } from "element-plus";
import { showI18nText } from "@/utils/i18n";

type Params = {
  tip?: string;
  message?: string;
  customClass?: string;
  showCancelButton?: boolean;
  dangerouslyUseHTMLString?: boolean;
}
/**
 * 生成一个二次确认框
 * @returns
 */
export function createMyComfirm(params: Params) {
  let _params = Object.assign({
    tip: "提示",
    message: "确定要删除吗？",
    customClass: "",
    showCancelButton: true,
    dangerouslyUseHTMLString: false,  // 是否可传入html片段
  }, {
    ...params
  })

  let _customClass = _params.customClass || "";
  return new Promise((resolve, reject) => {
    ElMessageBox.confirm(_params.message, _params.tip, {
      confirmButtonText: showI18nText("text12", "system"),
      cancelButtonText: showI18nText("text11", "system"),
      type: "info",
      roundButton: true,
      customClass: `${_customClass} my-confirm-box`,
      showClose: false,
      confirmButtonClass: "el-button--primary",
      cancelButtonClass: "el-primary",
      showCancelButton: _params.showCancelButton,
      dangerouslyUseHTMLString: _params.dangerouslyUseHTMLString,  // 是否可传入html片段
    })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject();
      });
  });
}
