<template>
    <el-dialog
        v-model="showFlag"
        :style="dialogStyle"
        align-center
        :show-close="false"
        :close-on-press-escape="false"
    >
        <!-- 弹窗标题部分 -->
        <template #header v-if="title">
            <slot name="header">
                <div class="dialog-header">
                    {{ title }}
                </div>
            </slot>
        </template>
        <!-- 弹窗主要内容 -->
        <template #default>
            <slot name="default"></slot>
        </template>
        <!-- 弹窗底部按钮等 -->
        <template #footer v-if="showFooter">
            <slot name="footer">
                <div
                    class="dialog-footer"
                    :class="{
                        'dialog-footer-right': buttonAlign === 'right',
                        'dialog-footer-left': buttonAlign === 'left',
                        'dialog-footer-center': buttonAlign === 'center',
                    }"
                >
                    <myButton
                        v-if="showLeftButton"
                        @click="onCancel"
                        :content="leftButtonTextI18n"
                        :size="buttonSize"
                    ></myButton>
                    <myButton
                        v-if="showRightButton"
                        type="primary"
                        @click="onConfirm"
                        :content="rightButtonTextI18n"
                        :size="buttonSize"
                    ></myButton>
                </div>
            </slot>
        </template>
    </el-dialog>
</template>
<script lang="ts" setup>
import { defineProps, defineModel, defineEmits, computed } from "vue";
import { showI18nText } from "@/utils/i18n";
const showFlag = defineModel();

const props = defineProps({
    title: { type: String, default: "Tips" },
    showFooter: { type: Boolean, default: true }, // 是否显示底部按钮，默认为true
    leftButtonText: { type: String, default: "Cancel" }, // 左侧按钮文字，默认为Cancel
    rightButtonText: { type: String, default: "Confirm" }, // 右侧按钮文字，默认为Confirm
    showLeftButton: { type: Boolean, default: true }, // 是否显示左侧按钮，默认为true
    showRightButton: { type: Boolean, default: true }, // 是否显示右侧按钮，默认为true
    buttonAlign: { type: String, default: "left" },
    width: { type: String, default: "480" },
    buttonSize: {type: String, default: 'default'}
});
const leftButtonTextI18n = computed(() =>
    props.leftButtonText == "Cancel" ? showI18nText("text11", "system") : props.leftButtonText
);
const rightButtonTextI18n = computed(() =>
    props.rightButtonText == "Confirm" ? showI18nText("text12", "system") : props.rightButtonText
);
const emits = defineEmits(["cancel", "confirm"]);

function onCancel() {
    emits("cancel");
    showFlag.value = false;
}
function onConfirm() {
    emits("confirm");
    // showFlag.value = false;
}

const dialogStyle = computed(() => ({
    width: isNaN(Number(props.width)) ? props.width : props.width + "px",
    "border-radius": "24px",
    padding: "37px 32px",
}));
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";

:deep(.el-dialog) {
    background: $bgColorFFF !important;
    border-radius: 24px !important;
}
.dialog-header {
    font-size: 24px;
    font-family: Poppins-SemiBold, Poppins;
    font-weight: 600;
    color: $color19;
    line-height: 36px;
}
.dialog-footer {
    display: flex;
    padding-top: 16px;
    &-left {
        justify-content: start;
    }
    &-center {
        justify-content: center;
    }
    &-right {
        justify-content: end;
    }
}
</style>
