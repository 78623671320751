import { createRouter, createWebHashHistory } from 'vue-router';
import { useUserInfoStore } from '@/stores/userInfo';
import { useSystemConfigStore } from '@/stores/systemConfig';
import { useOrderStore } from '@/stores/order';
import { getAllinfo } from '@/api/video';
import AES from "@/utils/aes";
import { replace, toLength } from 'lodash';
import { getCourseInfo } from '@/api/video'
import { ElMessage } from 'element-plus'
import { showI18nText } from "@/utils/i18n.js";
import { sendPV } from '@/utils/EventTracking.js'
import { createMyComfirm } from '@/utils/components'
import mitt from "@/utils/mitt";

export const constantRoutes = [
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    name: 'login',
  },
  {
    path: '/',
    component: () => import('@/views/layout/index.vue'),
    redirect: '/home',
    name: 'main',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index.vue'),
        name: 'home',
        meta: { title: '首页' }
      },
      {
        path: '/personal',
        component: () => import('@/views/personal/index.vue'),
        name: 'personal',
        meta: { title: '个人中心' }
      },
      {
        path: '/course/:unitId',
        component: () => import('@/views/course/index.vue'),
        name: 'course',
        meta: { title: '课程详情' }
      },
      {
        path: '/video/:id',
        component: () => import('@/views/video/index.vue'),
        name: 'video',
        meta: { title: '视频课程' }
      },
      {
        path: '/purchaseRecord',
        component: () => import('@/views/purchase/index.vue'),
        name: 'purchaseRecord',
        meta: { title: '购买记录' }
      },
    ]
  },

  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  // 404 page must be placed at the end !!!
  { path: '/:catchAll(.*)', redirect: '/404', hidden: true }
];

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
});

const whiteList = ['/login']; // no redirect whitelist
async function getAllInfo() {
  let { success, resultMap } = await getAllinfo();
  if (success) {
    // var dess = JSON.parse(AES.decrypt(resultMap, "6plwu888B@M1x917"));
    useSystemConfigStore().setData({
      baseUrl: resultMap,
    });

  }
}
let firstEnterFlag = true
router.beforeEach(async (to, from, next) => {
  console.log('router beforeEach');
  // 首次进入获取app config
  const systemConfigStore = useSystemConfigStore()
  const userInfoStore = useUserInfoStore()
  if (firstEnterFlag) {
    firstEnterFlag = false
    // 缓存问题，过一个版本可以删除
    if (typeof systemConfigStore.baseUrl === 'object' || typeof systemConfigStore._baseUrl === 'object' || systemConfigStore.baseUrl == 'baseUrl' || systemConfigStore._baseUrl == 'baseUrl' || systemConfigStore.baseUrl === '' || systemConfigStore._baseUrl === '') {
      systemConfigStore.baseUrl = '';
      systemConfigStore._baseUrl = '';
      userInfoStore.$resetUserInfo()
      await getAllInfo()
      next(`/login`)
    }
    // 删除到这
    await getAllInfo()
    // console.log('app config', systemConfigStore.decodeBaseUrl);
  }
  const hasToken = userInfoStore.token;
  // 路由守卫存分享链接的id和code
  if (to.path.includes("/course")) {
    userInfoStore.unitId = to.params.unitId || '' // 单元id
    userInfoStore.learnUnitCode = to.query.learnUnitCode || '' // 单元code
  } else if (to.path.includes("/video")) {
    userInfoStore.unitId = to.query.learningUnitId || '' // 单元id
    userInfoStore.courseId = to.params.id || '' // 课程id
    userInfoStore.courseCode = to.query.courseCode || '' // 课程code
  }

  if (hasToken) {
    if (to.path === '/login') {
      // if (userInfoStore.courseId) {
      //     next({ name: 'video', params: { id: userInfoStore.courseId } })
      // }
      next({ path: '/' });
    } else {
      try {
        if (to.path.includes("/video")) {
          let res = await getCourseInfo({
            courseId: to.params.id,
            userId: userInfoStore.userId,
            checkFlg: 1,
            courseCode: to.query.courseCode,
          })
          const { success, resultMap } = res
          if (success) {
            // 判断是否需要购买该课程 0:需要购买 1:免费课程 2:学习单元已过期 3:学习单元已购买
            if ('needToBuyFlg' in resultMap && resultMap.needToBuyFlg == 0) {
              // 未购买，预存路由，跳转到home页，弹出支付弹窗
              const orderStore = useOrderStore()
              orderStore.fromRouter = { name: to.name, query: {...to.query}, params: {...to.params} }
              createMyComfirm({
                tip: '',
                message: showI18nText('text28', 'home'),
              }).then(() => {
                if (orderStore.fromRouter.query.learningUnitId) {
                  setTimeout(() => {
                    mitt.emit('payBus', { unitId: orderStore.fromRouter.query.learningUnitId })
                  }, 500);
                }
              })
              next(`/home`);
            }
            // 判断课程是否在有效期内， 不在有效期内直接跳转到首页
            if ('learningUnitStatus' in resultMap && resultMap.learningUnitStatus != 1) {
              if (resultMap.learningUnitStatus == 0) {
                ElMessage({
                  type: "error",
                  message: showI18nText('text15', 'home'),
                })
                next(`/home`);
              } else if (resultMap.learningUnitStatus == 2) {
                ElMessage({
                  type: "error",
                  message: showI18nText('text16', 'home'),
                })
                next(`/home`);
              }
            }
            // 不能按顺序学习的提示置空，防止一些奇怪的bug
            userInfoStore.promptMessage = ''
            next()
          } else {
            userInfoStore.promptMessage = ''
            next();
          }
        } else {
          userInfoStore.promptMessage = ''
          next();
        }
      } catch (error) {
        next(`/home`);
      }
    }
  } else {

    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next(`/login`);
    }
  }
});

router.afterEach(() => {
  sendPV()
})

export default router;