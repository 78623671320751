// https://developer.umeng.com/docs/147615/detail/290919#h1-v1p-uni-pic
import { useUserInfoStore } from "@/stores/userInfo";
import { nextTick } from "vue";

async function uploadCommonConfig() {
  const _commonParams = await commonParams();
  // 循环上报默认上报信息
  Object.keys(_commonParams).forEach((key) => {
    metaPush(key, _commonParams[key]);
  })
}
nextTick(() => uploadCommonConfig());

/**
 * 用于变更SDK的默认设置
 * @param {*} metaName 为可配置的元数据项，可配置的 meta项见 第一行 文档附表1：metaName 及 metaValue 对应表
 * @param {*} metaValue 为对应的元配置项取值
 * @param {*} mode 为模式，其取值为枚举值：  
 * "OVERWRITE": 默认值，覆盖模式，原 meta 值将直接被覆盖，注意对于取值为单值的 meta 只有覆盖模式  
 * "APPEND": 追加模式，仅适用于 meta 取值为数组对象的 meta，追加模式下原取值保留  
 */
export function metaPush(metaName, metaValue, mode = 'OVERWRITE') {
  // console.log('-- 埋点 -- 上报默认信息', metaName, metaValue);
  const { aplus_queue } = window;
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: [metaName, metaValue, mode]
  });
}

/**
 * 用于异步上报变更SDK的默认设置
 * @param {string} key 
 * @param {string} value 
 */
export function asyncPush(key, value, mode) {
  //如采集用户信息是异步行为，需要先阻止SDK上报，设置BLOCK埋点
  const { aplus_queue } = window;
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ['_hold', 'BLOCK']
  });

  //异步耗时流程 (如获取user_id)
  metaPush(key, value, mode)

  // 因为采集用户信息是异步行为，故需要先设置BLOCK，再设置START
  // 设置_hold=START后，事先被block住的日志会携带上用户信息逐条发出
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ['_hold', 'START']
  });
}

/**
 * 若当前项目为单页面应用时，需要在路由跳转的时候手动发送一次PV，使用下方的PV发送事件发送，此API并非是自定义事件，arguments的属性不在后台中展示，
 * 自定义事件请参考下方页面点击事件
 */
export function sendPV() {
  // console.log('-- 埋点 -- 自动 PV');
  const { aplus_queue } = window;
  aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [{ is_auto: false }] // 此处上报的数据暂时在后台没有展示
  });
}

/**
 * 用于上报自定义点击事件
 * @param {string} eventCode 事件ID 或 事件编码，字符串类型
 * @param {object} eventParams 为本次事件中上报的事件参数。其取值为一个JSON对象（平铺的简单对象，不能多层嵌套）  
 *         * SDK保留属性：uid, aplus, spm-url, spm-pre, spm-cnt, pvid,dev_id,anony_id,user_id,user_nick, _session_id
 */
export async function recordClickEvent(eventCode, eventParams = {}) {
  const params = { ...eventParams }
  // 将参数值全部转变为字符串类型
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      params[key] = String(params[key]);
    }
  }
  // console.log('-- 埋点 -- 点击事件', eventCode, params);
  const { aplus_queue } = window;
  aplus_queue.push({
    action: 'aplus.record',
    arguments: [eventCode, 'CLK', params]
  });
  // console.log('自定义埋点', eventCode, { ...params, ...eventParams });
}

/**
 * 自动曝光事件 
 * @param {string} logkey 事件管理中的事件ID
 * @param {string} cssSelector 需要曝光的元素class
 * @param {string[]} props 要曝光的元素身上自定义属性（data-*)，作为参数上报
 * @param {string} positionSelector 如果页面模块是元素内滚动（某个区块内有滚动条）则需要增加positionSelector辅助定位曝光元素
 */
export async function autoExposureEvent(logkey, cssSelector, props = [], positionSelector = '') {
  // console.log('-- 埋点 -- 自动曝光事件', logkey, props, cssSelector, positionSelector);
  const { aplus_queue } = window;
  aplus_queue.push({
    action: 'aplus.setMetaInfo',
    arguments: ['aplus-auto-exp', [
      {
        cssSelector: `.${cssSelector}`,
        positionSelector: positionSelector ? `.${positionSelector}` : '',
        logkey,
        props,
      },
    ],
    ],
  });
  // console.log('自动曝光事件 ', logkey, cssSelector, props, positionSelector);
}

/**手动曝光 */
// export function exposureEvent(pageName) {
//     const { aplus_queue } = window;
//     aplus_queue.push({
//         action: 'aplus.record',
//         arguments: ['$$_exposure', 'EXP', {
//             //key为字符串类型, value为字符串或Number类型，长度均限制在128位字符以内
//             id1: "testItemId1",
//             id2: "testItemId2",
//             id3: "testItemId3"
//         }]
//     });
// }

async function commonParams() {
  const userInfoStore = useUserInfoStore();
  const params = {
    // 设备信息
    ip: await getUserIP(),
    browserInfo: getBrowserInfo().join(' '),
    osVersion: getOsInfo(),
    // App标识
    app: 'aic',
    // 用户信息
    userId: userInfoStore?.userId || '',
    _user_id: userInfoStore?.userId || '',
    visitorId: userInfoStore?.visitorId || '',
    isNewUser: userInfoStore?.isNewUser ? 'Y' : 'N',
  }
  const { ip, browserInfo, osVersion, app, userId, _user_id, visitorId, isNewUser } = params;
  return {
    ...params,
    globalproperty: { ip, browserInfo, osVersion, app, userId, _user_id, visitorId, isNewUser }
  };
}

// 获取IP地址
async function getUserIP() {
  const ip = sessionStorage.getItem('ip')
  if (ip) return ip
  return fetch('https://api.ipify.org?format=json')
    .then(response => response.json()) // 解析JSON响应数据
    .then(data => {
      sessionStorage.setItem('ip', data.ip)
      return data.ip; // 获取IP地址
    })
}

// 获取浏览器信息
function getBrowserInfo() {
  var agent = navigator.userAgent.toLowerCase();
  var arr = [];
  // var system = agent.split(' ')[1].split(' ')[0].split('(')[1];
  // arr.push(system);
  var regStr_edge = /edge\/[\d.]+/gi;
  var regStr_ie = /trident\/[\d.]+/gi;
  var regStr_ff = /firefox\/[\d.]+/gi;
  var regStr_chrome = /chrome\/[\d.]+/gi;
  var regStr_saf = /safari\/[\d.]+/gi;
  var regStr_opera = /opr\/[\d.]+/gi;
  //IE
  if (agent.indexOf("trident") > 0) {
    arr.push(agent.match(regStr_ie)[0].split('/')[0]);
    arr.push(agent.match(regStr_ie)[0].split('/')[1]);
    return arr;
  }
  //Edge
  if (agent.indexOf('edge') > 0) {
    arr.push(agent.match(regStr_edge)[0].split('/')[0]);
    arr.push(agent.match(regStr_edge)[0].split('/')[1]);
    return arr;
  }
  //firefox
  if (agent.indexOf("firefox") > 0) {
    arr.push(agent.match(regStr_ff)[0].split('/')[0]);
    arr.push(agent.match(regStr_ff)[0].split('/')[1]);
    return arr;
  }
  //Opera
  if (agent.indexOf("opr") > 0) {
    arr.push(agent.match(regStr_opera)[0].split('/')[0]);
    arr.push(agent.match(regStr_opera)[0].split('/')[1]);
    return arr;
  }
  //Safari
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
    arr.push(agent.match(regStr_saf)[0].split('/')[0]);
    arr.push(agent.match(regStr_saf)[0].split('/')[1]);
    return arr;
  }
  //Chrome
  if (agent.indexOf("chrome") > 0) {
    arr.push(agent.match(regStr_chrome)[0].split('/')[0]);
    arr.push(agent.match(regStr_chrome)[0].split('/')[1]);
    return arr;
  } else {
    arr.push('Unknow')
    arr.push('Unknow')
    return arr;
  }
}

// 获取操作系统版本 不准确
function getOsInfo() {
  var userAgent = navigator.userAgent.toLowerCase();
  var name = 'Unknown';
  var version = 'Unknown';
  var regAndroid = /android [\d._]+/gi;
  var regiPhone = /os [\d._]+/gi;
  var regMacOS = /x [\d._]+/gi;
  var regWin = /nt [\d._]+/gi;
  var v_info = '';
  if (userAgent.indexOf('win') > -1) {
    name = 'Windows';
    v_info = userAgent.match(regWin);
    version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, ".");
  } else if (userAgent.indexOf('iphone') > -1) {
    name = 'iPhone';
    v_info = userAgent.match(regiPhone);
    version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, ".");
  } else if (userAgent.indexOf('mac') > -1) {
    name = 'Mac';
    v_info = userAgent.match(regMacOS);
    version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, ".")
  } else if (userAgent.indexOf('x11') > -1 || userAgent.indexOf('unix') > -1 || userAgent.indexOf('sunname') > -1 || userAgent.indexOf('bsd') > -1) {
    name = 'Unix';
  } else if (userAgent.indexOf('linux') > -1) {
    if (userAgent.indexOf('android') > -1) {
      name = 'Android';
      v_info = userAgent.match(regAndroid);
      version = (v_info + "").replace(/[^0-9|_.]/ig, "").replace(/_/ig, ".");
    } else {
      name = 'Linux';
    }
  } else {
    name = 'Unknown';
  }
  // return { name, version };
  return `${name} ${version}`
}